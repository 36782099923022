.header-content {
    text-align: center;
    padding: 20px;
}

.tabs {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.form-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.form-section {
    width: 30%;
    padding: 10px;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.18);
}

.live-preview {
    margin-top: 20px;
    padding: 10px;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.18);
}
