.greeting-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: url('./greet.jpg') no-repeat center center/cover;
  position: relative;
  padding: 20px;
}

.welcome-text {
  position: absolute;
  left: 40px;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  text-align: left;
}

.animated-text {
  display: flex;
  gap: 5px;
}

.drop-letter {
  font-size: 90px;
  font-weight: bold;
  color: #fff;
  animation: drop-bounce 2s ease forwards;
  opacity: 100;
}

@keyframes drop-bounce {
  0% {
    transform: translateY(-150px);
    opacity: 0;
  }
  60% {
    transform: translateY(20px);
    opacity: 1;
  }
  80% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

.drop-flour {
  font-size: 60px;
  color: #fff;
  margin: 0;
  opacity: 0;
  animation: drop-left 2s ease forwards;
  animation-delay: 1.8s; /* Starts after "Ujjal" animation */
}

@keyframes drop-left {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.drop-since {
  font-size: 24px;
  margin: 0;
  opacity: 0;
  animation: drop-left 2s ease forwards;
  animation-delay: 3.5s; /* Starts after "Flour Mills" animation */
}

.button-container {
  display: flex;
  gap: 10px;
  position: absolute;
  bottom: 80px;
  left: 40px;
}

.glass-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 40px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  animation: gooey-wiggle 4s infinite ease-in-out;
}

.glass-button:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
}

.glass-button span {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  user-select: none;
  pointer-events: none;
}

@keyframes gooey-wiggle {
  0%,
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    transform: translate(-2px, 1px) rotate(-1deg);
  }
  40% {
    transform: translate(2px, -1px) rotate(1deg);
  }
  60% {
    transform: translate(-1px, 1px) rotate(-0.5deg);
  }
  80% {
    transform: translate(1px, -1px) rotate(0.5deg);
  }
}
