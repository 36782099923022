.login-container {
  display: flex;
  justify-content: flex-start; /* Aligns the container to the left */
  align-items: center;
  height: 100vh;
  background: url('./greet.jpg') no-repeat center center/cover; /* Set the correct path for the image */
  padding-left: 120px; /* Adds some space from the left edge */
}

.login-box {
  background: rgba(255, 255, 255, 0.1);
  padding: 28px;
  border-radius: 20px;
  backdrop-filter: blur(1px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 300px; /* Sets a fixed width for the login form */
}

.login-box:hover {
  transform: scale(1.05);
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.3);
}

h2 {
  margin-bottom: 5px;
  color: #fff;
  font-size: 24px;
}

.input-group {
  margin-bottom: 20px;
  text-align: left;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  color: #fff;
}

.input-group input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  background: rgba(255, 255, 255, 0.2);
  color: #333;
}

.login-button {
  width: 100%;
  padding: 10px;
  background-color: #004080;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: #002d5a;
}
