.admin-dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 20px;
}

.buttons-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.button-wrapper {
  position: relative;
}

.glass-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 50px;
  background: rgba(0, 50, 100, 0.8);
  border-radius: 10px;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.18);
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.popup-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px; /* Increased width for larger content */
  max-height: 90vh; /* Ensure the popup fits within the viewport */
  overflow-y: auto; /* Enable scrolling if content overflows */
  background: rgba(0, 0, 50, 0.85);
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-section {
  width: 100%; /* Full width to utilize space */
  height: 100%; /* Full height to utilize space */
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(0, 0, 50, 0.85);
  border-radius: 10px;
  padding: 20px;
}

.form-title {
  width: 100%;
  font-size: 24px;
  font-weight: bold;
  color: white;
  margin-bottom: 10px;
  text-align: center;
}

input,
button {
  width: 80%;
  margin: 10px 0;
  padding: 12px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  background: rgba(0, 0, 50, 0.4);
  color: white;
  font-size: 14px;
}

button {
  background: rgba(0, 50, 100, 0.8);
  cursor: pointer;
  transition: background 0.3s;
}

button:hover {
  background: rgba(0, 50, 120, 1);
}

.message-popup {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  background-color: rgba(0, 128, 0, 0.8);
  color: #fff;
  border-radius: 5px;
  font-size: 16px;
  z-index: 1000;
  animation: fade-in-out 4s ease-in-out forwards;
}

@keyframes fade-in-out {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.customer-list {
  list-style: none;
  padding: 0;
  margin: 10px 0;
}

.customer-list li {
  padding: 10px;
  background: rgba(255, 255, 255, 0.6); /* Brighter background */
  margin-bottom: 5px;
  cursor: pointer;
  border-radius: 5px;
  color: #000; /* Dark text for contrast */
  font-weight: bold; /* Make text bold */
  transition: background 0.3s ease, color 0.3s ease;
}

.customer-list li:hover {
  background: rgba(255, 255, 255, 0.8); /* Even brighter on hover */
  color: #000; /* Keep the text color dark */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Add a subtle shadow on hover */
}

.customer-profile {
  padding: 20px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  backdrop-filter: blur(15px);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.18);
  max-width: 500px;
  margin: 20px auto;
  text-align: left;
  color: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.customer-name {
  font-size: 24px;
  font-weight: bold;
  color: #e0e0e0;
}

.profile-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid rgba(255, 255, 255, 0.3);
}

.info-list {
  width: 100%;
  padding: 0;
  list-style: none;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.info-list li {
  padding: 10px;
  background: rgba(0, 50, 100, 0.6);
  border-radius: 5px;
  color: #e0e0e0;
}

.back-button {
  margin-top: 20px;
  padding: 10px 20px;
  background: rgba(0, 50, 100, 0.8);
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  transition: background 0.3s ease;
}

.back-button:hover {
  background: rgba(0, 50, 120, 1);
}
.search-input {
  width: 90%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.7);
  color: #000;
  font-size: 14px;
  outline: none;
  transition: background 0.3s ease;
}

.search-input::placeholder {
  color: #555;
}


.pagination {
  display: flex;
  gap: 5px;
  justify-content: center;
  margin-top: 10px;
}

.pagination-button {
  padding: 5px 10px;
  background: rgba(0, 50, 100, 0.8);
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  transition: background 0.3s ease;
}

.pagination-button.active {
  background: rgba(0, 50, 120, 1);
}

.pagination-button:hover {
  background: rgba(0, 50, 120, 0.9);
}
